@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
$site_main_color1: #FFF15C;
$site_main_color: #FFF047;
$site_accent_color: #8C59D9;
$site_accent_color1: #CFA0C8;

@media (max-width: 768px) {
    .sidebar {
        width: 80px;

        &.collapsed {
            width: 50px;
        }

        .toggle-btn {
            display: none;
        }
    }
    .content {
        margin-left: 80px;
    }

}

* {
    font-size: 1rem;
    color: black;
    font-family: 'Montserrat', sans-serif;
}
.site-link, a {
    text-decoration: none;
    color: inherit;
}



body {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}

.bg-purple {
    background-color: $site_accent_color;
}
.bg-yellow {
    background-color: $site_main_color;

}
.bg-off-white {
    background-color: rgba(256,256,256, .7);
}

.blog-pages {
    min-width: 150px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
}
.blog-post-2 {
    width: 350px;
    height: 250px;
}

.border-purple {
    border-color: $site_accent_color;
}

.brand-border {
    border-color: $site_accent_color;
}
.brand-border-1 {
    border-color: #FFF047;
}
.box-shadow-1 {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.box-shadow-2 {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.box-shadow-3 {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.box-shadow-4 {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.box-shadow-5 {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.box-shadow-6 {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.box-shadow-7 {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.box-shadow-8 {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.carousel {
    width: 100%;
    height: 500px;
}
.carousel-inner, .carousel-item {
    height: 100%;
}
.carousel-caption.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.circle {
    border-radius: 100%;
}

.fixed-content {
    top: 0;
    bottom:0;
    position:fixed;
    min-width: 300px;
    max-width: 800px;
    overflow-y:scroll;
    overflow-x:hidden;
}

.fc-purple {
    color: $site_main_color;
}
.fs-title {
    font-size: 1.2rem;
}



.guide-card {
    min-width: 200px;
    max-width: 180px;
    height: 250px;
}

.guide-card-img {
    width: 50px;
    height: 50px;
}

.h-10 {
    height: 10%;
}
.h-20 {
    height: 20%;
}
.h-30 {
    height: 30%;
}
.h-40 {
    height: 40%;
}
.h-60 {
    height: 60%;
}
.h-70 {
    height: 70%;
}
.h-80 {
    height: 80%;
}
.h-90 {
    height: 90%;
}

.h-fit-content {
    height: fit-content;
}

.hr-purple {
    color: $site_accent_color;
}

.limited-view {
    filter: blur(10px);
    -webkit-filter: blur(10px);
}

.link-purple {
    color: $site_accent_color;
}
.link-purple:hover {
    color: #575757;
}

.navbar, .footer {
    background-color: $site_accent_color;
}

.nav-pills .nav-link, .nav-underline .nav-link {
    color: $site_accent_color !important;
    margin: auto 15px;
}

.nav-pills .nav-link.active {
    background-color: $site_accent_color;
    font-weight: 600;

    color: #ffffff !important;
}

.nav-underline .nav-link.active {
    color: $site-accent_color !important;
}
.pages-section {
    min-width: 500px;
    max-width: 700px;
    min-height: 200px;
    max-height: 300px;
}

.payment-card {
    min-width: 350px;
    max-width: 400px;
    min-height: 300px;
    max-height: 400px;
}

.post-page {
    height: fit-content;
    width: fit-content;
    min-height: 20vh;
    max-height: 70vh;
    min-width: 350px;
    max-width: 100%;
}
.post-overlay {
   min-height: 10vh;
    max-height: 40vh;
}

.rem-1 {
    font-size: 1em;
}
.rem-08 {
    font-size: .8em;
}
.rem-09 {
    font-size: .9em;
}

.sample-papers-section, .testimonials, .guide-section {
    height: 350px;
    width: 90vw;

}
.sample-post {
    min-width: 300px;
    max-width: 350px;
    min-height: 150px;
    max-height: 280px;
}

.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 200px;
    height: 100%;
    transition: width .3s;

    &.collapsed {
        width: 80px;

        ul {
            l{
                a{
                    span{
                        display: none;
                    }
                }
            }
        }
    }
    .toggle-btn {
        background-color: $site_accent_color;
        color: black;
        border: none;
        padding: 10px;
        width: 100%;
        cursor: pointer;
        text-align: left;
        transition: background-color 0.3s;

        &:hover {
            background-color: #575757;
        }
    }

    ul {
        list-style-type: none;
        padding: 0;

        li {
            padding: 10px;
            text-align: left;

            a {
                color: #ffffff;
                transition: background-color 0.3s;
            }

            &:hover {
                background-color: #575757;
            }
        }
    }
}

.site-btn {
    background-color: $site_accent_color;
    color: white;
    border: none;
}

.site-btn:hover {
    background-color: $site_accent_color;
    border: 1px solid $site_accent_color;
    color: white;
}

.site-form {
    min-width: 400px;
    max-width: 1000px;
}

.small {
    font-size: .95rem;
}
.smaller {
    font-size: .85rem;
}

.stat {
    min-width: 120px;
    max-width: 150px;
    min-height: 120px;
    max-height: 150px;
}
.stat-bg-purple {
    background-color: $site_accent_color;
}
.stat-outline {
    border: 1.7px solid $site_accent_color;
}

.subject-tab {
    min-width: 100px;
    max-width: 120px;
    min-height: 100px;
    max-height: 120px;
}

.smaller {
    font-size: .75em;
}
.subject-tabs {
    min-width: 400px;
    max-width: 900px;
}
.testimonial {
    min-width: 300px;
    max-width: 300px;
}

.testimonial-comment {
    min-height: 90px;
    max-height: 90px;
    text-overflow: ellipsis;
}

.text-purple {
    color: $site_accent_color !important;
}

.text-high-urgency {
    color: #C1121F !important;
}
.text-medium-urgency {
    color: #e09f08 !important;
}
.text-low-urgency {
    color: #003049 !important;
}
.text-pending {
    color: green !important;
}

.bg-high-urgency {
    background-color: #C1121F !important;
}
.bg-medium-urgency {
    background-color: #e09f08 !important;
}
.bg-low-urgency {
    background-color: #003049 !important;
}
.bg-pending {
    background-color: green !important;
}

.w-10 {
    width: 10%;
}
.w-20 {
    width: 20%;
}
.w-30 {
    width: 30%;
}
.w-40 {
    width: 40%;
}
.w-60 {
    width: 60%;
}
.w-70 {
    width: 70%;
}
.w-80 {
    width: 80%;
}
.w-90 {
    width: 90%;
}

.w-fit-content {
    width: fit-content;
}

/* COMMENTS */
.comments-container {
    max-height: 50vh;
}

.comment-container {
    width: 90%;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 5px;
}

.comment-body {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 10px;
}

.current-user .comment-body {
    background-color: #d1ffd6;  /* Light green for current user */
}

.other-user .comment-body {
    background-color: #f1f1f1;  /* Light grey for other users */
}

.comment-meta {
    font-size: 0.85rem;
    color: #888;
    text-align: right;
    margin-top: 5px;
}


/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
    .form-column {
        border: none;
    }

    .files {
        width: 100%;
    }

    
 }
 
 /* Small Devices, Tablets */
 @media only screen and (min-width : 768px) {
    .form-column {
        border: none;
    }

    .files {
        width: 100%;
    }
 }
 
 /* Medium Devices, Desktops */
 @media only screen and (min-width : 992px) {
    .form-column {
        border-left: 1px solid $site-accent-color;
    }

    .files {
        width: 75%;
    }

    
 }
 
 /* Large Devices, Wide Screens */
 @media only screen and (min-width : 1200px) {
    .files {
        width: 50%;
    }

    
 }

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$utilities: map-merge(
  $utilities,
  (
    "min-viewport-height": (
        property: min-height,
        class: min-vh,
        values: (100: 100vh, 75: 75vh, 50: 50vh),
    )
  )
);

@import "bootstrap/scss/utilities/api";


.text-justify {
    text-align: justify;
}
